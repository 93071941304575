
<template>
  <div class="productDetailsBox page-info-content">
    <template>
      <el-button v-if="detailObj.status === -1" type="primary" size="small" class="confirm-audit" @click="confirmAudit">
        审核
      </el-button>
    </template>
    <!-- 产品详情 -->
    <Details-page
      :list-data="listData
      "
      :details-title="'基本信息'"
      :detail-obj="detailObj"
      :details-item-arr="detailsItemArr"
    >
      <template>
        <el-descriptions v-if="detailObj.source!==2" :column="3" title="融资方案配置">
          <el-descriptions-item label="融资比例">
            {{ detailObj.loanRate }}%
          </el-descriptions-item>
          <el-descriptions-item label="还款方式">
            <span v-if=" detailObj.repayType === 1">等额本息</span>
            <span v-else-if="detailObj.repayType === 2">到期一次性还本付息</span>
            <span v-else>按月结息到期还本</span>
          </el-descriptions-item>
          <el-descriptions-item label="结息方式">
            {{ detailObj.interestPayType === 1 ? "定日结息" : "贷方日结息" }}
          </el-descriptions-item>
          <el-descriptions-item label="支付方式">
            <span v-if=" detailObj.paymentType === 1">定向支付</span>
            <span v-else-if="detailObj.paymentType === 0">自主支付</span>
          </el-descriptions-item>
          <el-descriptions-item label="贷款周期">
            {{ detailObj.maxLoanCycle || 0 }}
            <span v-if=" detailObj.maxLoanCycleUnit === 1">天</span>
            <span v-else-if="detailObj.maxLoanCycleUnit === 2">月</span>
            <span v-else>年</span>
          </el-descriptions-item>
          <el-descriptions-item label="贷款展期期限">
            {{ detailObj.loanExtensionPeriod||0 }}
            <span v-if=" detailObj.maxLoanCycleUnit === 1">天</span>
            <span v-else-if="detailObj.maxLoanCycleUnit === 2">月</span>
            <span v-else>年</span>
          </el-descriptions-item>
          <el-descriptions-item label="是否需要担保">
            {{ detailObj.needGuarantee === 1 ? "无担保" : "有担保" }}
          </el-descriptions-item>
          <el-descriptions-item v-if="detailObj.needGuarantee === 2" label="担保方式">
            <span v-if="detailObj.guaranteeList[0].guaranteeType===1">信用担保</span>
            <span v-else-if="detailObj.guaranteeList[0].guaranteeType===2">抵押担保</span>
            <span v-else-if="detailObj.guaranteeList[0].guaranteeType===3">质押担保</span>
            <span v-else-if="detailObj.guaranteeList[0].guaranteeType===4">保证担保</span>
          </el-descriptions-item>
          <el-descriptions-item v-if="detailObj.needGuarantee === 2" label="担保公司">
            {{ detailObj.guaranteeList[0].guaranteeName }}
          </el-descriptions-item>
          <el-descriptions-item v-if="detailObj.needGuarantee === 2" label="担保协议模板">
            {{ detailObj.guarCompcatName }}
          </el-descriptions-item>
        </el-descriptions>

        <h3 class="page-subtitle-shade">
          融资所需合同
        </h3>
        <Table
          :operation-button-width="236"
          :item-data="financingItemData"
          :operation-button="operationButton"
          :list-data="detailObj.agreementList"
        />
        <h3 class="page-subtitle-shade">
          费用配置
        </h3>
        <Table
          :operation-button-width="236"
          :item-data="costItemData"
          :list-data="detailObj.costRuleList"
        />
        <h3 class="page-subtitle-shade">
          授信所需资料
        </h3>
        <Table
          :operation-button-width="236"
          :item-data="creditItemData"
          :list-data="detailObj.documentList"
        />
        <el-descriptions :column="1">
          <el-descriptions-item label="备注">
            {{ detailObj.remarks }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Details-page>
    <!-- 审核弹窗 -->
    <Dialog ref="dialog" dialog-width="40%" :form-item="formItem" @getFormData="getFormData" @changeFormSelect="changeFormSelect" />
  </div>
</template>
<script>
import DetailsPage from '@/components/DetailsPage.vue'
import Table from '@/components/Table'
import { productDetails, productAudit } from '@/api/orderFinancing.js'
import { getResourceByTask } from '@/api/memberManagement'
import { downloadFile2 } from '@/utils/util'
import Dialog from '@/components/Dialog.vue'
export default {
  components: { Table, DetailsPage, Dialog },
  data() {
    return {
      formItem: [
        {
          label: '审核状态',
          type: 'select',
          value: 'approveResult',
          optionLabel: 'VARIABLE_NAME',
          optionId: 'VARIABLE_CODE',
          child: []
        },
        {
          label: '原因',
          type: 'input',
          value: 'approveAdvise',
          rules: 'none'
        }
      ],
      detailsItemArr: [
        { label: '产品编号', value: 'productNo' },
        { label: '资金方', value: 'funderName' },
        { label: '融资方案来源', value: 'source', child: [{ id: 1, val: '后台录入' }, { id: 2, val: '资方确定' }] },
        { label: '产品状态', value: 'status', child: [
          { dictName: '待审核', dictId: -1 },
          { dictName: '待生效', dictId: -2 },
          { dictName: '有效', dictId: 1 },
          { dictName: '无效', dictId: 2 }] }
      ],
      operationButton: [
        { bType: 'text', label: '查看', handleEvent: this.checkFile },
        { bType: 'text', label: '下载', handleEvent: this.downloadFiles }
      ],
      loading: false,
      // 融资所需合同
      financingItemData: [
        { label: '合同类型', prop: 'compactType', width: '60', child: this.$store.getters.getDictionaryItem('compactType') },
        { label: '合同模板', prop: 'compactName', width: '100' }
      ],
      // 费用配置
      costItemData: [
        { label: '费用类型', prop: 'costName', width: '100' },
        { label: '收费方式', prop: 'charging', width: '100' },
        { label: '单笔最低收取（元）', prop: 'minCharging', width: '100' },
        {
          label: '资方是否代扣',
          prop: 'funderWithhold',
          width: '100',
          child: [
            { value: '1', label: '是' },
            { value: '2', label: '否' }
          ]
        },
        { label: '收取方', prop: 'receiver', width: '100', child: [{ value: 1, label: '资金方' }, { value: 2, label: '担保方' }, { value: 3, label: '平台方' }] },
        { label: '支付方', prop: 'payer', width: '100', child: [{ value: 1, label: '购买方' }, { value: 2, label: '销售方' }] }
      ],
      // 授信所需资料
      creditItemData: [
        { label: '材料名称', prop: 'documentName', width: '150' },
        { label: '材料类型', prop: 'documentType' },
        {
          label: '是否必传',
          prop: 'necessary',
          width: '100',
          child: [
            { value: '1', label: '是' },
            { value: '2', label: '否' }
          ]
        }
      ],
      creditListData: [],

      listData: [],
      // 详细
      detailObj: {
        agreementList: [], // 融资合同模板
        costRuleList: [], // 费用配置
        documentList: [], // 授信所有资料
        guaranteeList: [] // 产品担保方
      },
      // 产品状态颜色
      statusCode: ['status-code1', 'status-code2'],
      resourceByTaskObj: {}
    }
  },
  created() {
    if (this.$route.query.id) {
      this.detailsAll(this.$route.query.id)
      this.resourceByTaskObj = this.$store.state.resourceByTaskObj || JSON.parse(sessionStorage.getItem('resourceByTaskObj'))
    }
  },
  methods: {
    // 下载文件
    downloadFiles(row) {
      const type = row.url.split('.')[1]
      downloadFile2(this.$fileUrl + row.url, row.compactName + '.' + type, type)
    },
    // 文件查看
    checkFile(row) {
      const type = row.url.split('.')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg' || type === 'pdf') {
        window.open(this.$fileUrl + row.url)
        return
      }
      this.$message.error(`暂不支持打开${type}文件`)
    },
    // productDetails  详情
    detailsAll(id) {
      productDetails(id, (res) => {
        this.detailObj = { ...res.data }
        this.detailObj.agreementList = res.data.agreementList || []
        this.detailObj.costRuleList = res.data.costRuleList || []
        this.detailObj.costRuleList.forEach((item) => {
          if (item.chargingRule === 2) {
            item.chargingRule = '%/年'
            item.charging = item.charging + item.chargingRule
            return
          } else if (item.chargingRule === 3) {
            item.chargingRule = '元/笔'
            item.charging = item.charging + item.chargingRule
            return
          } else {
            item.chargingRule = '%'
            item.charging = item.charging + item.chargingRule
          }
        })
        this.detailObj.guaranteeList = res.data.guaranteeList || []
        this.detailObj.documentList = res.data.documentList || []
      })
    },
    // 确认审核按钮
    confirmAudit() {
      this.$refs.dialog.editFormVisible = true
      getResourceByTask(this.resourceByTaskObj, res => {
        this.formItem.forEach(item => {
          if (item.value === 'approveResult') {
            item.child = [...res.data]
          }
        })
      })
    },
    // 审核提交
    getFormData(value) {
      const obj = {
        ...value,
        applyCode: this.detailObj.id,
        activiteId: this.resourceByTaskObj.activiteId,
        cmpState: this.detailObj.auditStatus,
        currentNodeId: this.resourceByTaskObj.currentNodeId
      }
      productAudit(obj, () => {
        this.$refs.dialog.editFormVisible = false
        this.$message.success('成功！')
        this.$router.go(-1)
      })
    },
    changeFormSelect(val) {
      if (val === 'approve_refuse') return delete this.formItem[1].rules
      if (val === 'approve_refuse') return this.formItem[1].rules
      this.$set(this.formItem[1], 'rules', 'none')
    }
  }
}
</script>

<style lang="scss" scoped>
.productDetailsBox {
  .page-subtitle-shade {
    margin-bottom: 20px !important;
    margin-left: 20px;
  }

  // /deep/.tableConponents .el-table {
  //   width: 70% !important;
  // }
  .tableConponents {
    // margin-left: 64px;
    margin: auto;
    text-align: center;
    width: calc(100% - 128px);
  }
  .status-code1 {
    color: #52c41a;
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    padding: 3px 5px;
    border-radius: 5px;
  }
  .status-code2 {
    color: #1890ff;
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
    padding: 3px 5px;
    border-radius: 5px;
  }

  .tableConponents {
    margin-bottom: 10px;
  }

  .confirm-audit {
    width: 100px;
    position: absolute;
    right: 60px;
    top: 60px;
    z-index: 11;
  }
}
</style>
